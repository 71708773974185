<script setup>
import SurveyBox from "./SurveyBox.vue";
import HomeSkillBuilding from "./HomeSkillBuilding.vue";
import HomeCohortReports from "./HomeCohortReports.vue";
import HomeFacultyReport from "./HomeFacultyReport.vue";
import HomeAdminTasks from "./HomeAdminTasks.vue";
import HomeReports from "./HomeReports.vue";
</script>

<template>
  <div class="body-form-container">
    <h3 class="message">
      *UAT Updated - January 22, 2025* Congratulations for investing in yourself. Use the following resources to
      guide your continuous development process.
    </h3>
    <div
      v-if="
        role.includes('10') ||
        role.includes('11') ||
        role.includes('12') ||
        role.includes('13') ||
        role.includes('14') ||
        role.includes('18')
      "
    >
      <HomeAdminTasks label="The following tasks are available for you" />
      <HomeSkillBuilding
        label="My Skill Building Actions"
        :userData="loadedUserData"
        :brandData="loadedBrandData"
        @passSubjectFullName="getSubjectFullName"
      />
      <HomeReports label="My Reports Available" :userData="loadedUserData" :brandData="loadedBrandData"/>
    </div>
    <div v-else-if="role !== ''">
      <HomeSkillBuilding
        label="My Skill Building Actions"
        :userData="loadedUserData"
        :brandData="loadedBrandData"
        @passSubjectFullName="getSubjectFullName"
      />
      <HomeReports label="My Reports Available" :userData="loadedUserData" :brandData="loadedBrandData"/>
      <br />
    </div>
    <div v-if="role.includes('4')">
      <HomeCohortReports
        reportName="5 Step Profile Report"
        surveyName="Big 5 Survey"
        dateTime="2022-03-23, 9:11:14 AM"
        buttonLabel="View report"
        buttonColor="#e67829"
        label="Your coaching reports available"
        :userData="loadedUserData"
        :brandData="loadedBrandData"
      />
      <br />
    </div>
    <div v-if="role.includes('5')">
      <HomeFacultyReport
        reportName="5 Step Profile Report"
        surveyName="Big 5 Survey"
        dateTime="2022-03-23, 9:11:14 AM"
        buttonLabel="View report"
        buttonColor="#e67829"
        label="Your faculty reports"
        :userData="loadedUserData"
        :brandData="loadedBrandData"
      />
      <br />
    </div>
  </div>
</template>
<script>
export default {
  props: ["userData", "brandData"],
  component: [
    SurveyBox,
    HomeSkillBuilding,
    HomeCohortReports,
    HomeFacultyReport,
    HomeAdminTasks,
    HomeReports,
  ],
  name: "HomeComponent",
  data: () => ({
    headerSubjectFullName: "",
    loadedUserData: [],
    loadedBrandData: [],
  }),
  mounted() {
    if(this.userData){
      this.loadedUserData = this.userData;
    }

    if(this.brandData){
      this.loadedBrandData = this.brandData
    }

    this.checkLoginAndBrandData()
    // old local storage
    // if(JSON.parse(localStorage.getItem('passLoginUp'))){
    //   let data = JSON.parse(localStorage.getItem('passLoginUp'))
    //   this.loadedUserData = data
    // }
    // if(JSON.parse(localStorage.getItem('passBrandUp'))){
    //   let data = JSON.parse(localStorage.getItem('passBrandUp'))
    //   this.loadedBrandData = data
    // }

  },
  methods: {

    checkLoginAndBrandData() {
      const loginData = this.getCookie('passLoginUp')
      if (loginData) {
        this.loadedUserData = JSON.parse(loginData)
      }

      const brandData = this.getCookie('passBrandUp')
      if (brandData) {
        this.loadedBrandData = JSON.parse(brandData)
      }
    },

    setCookie(name, value, hours) {
      const expires = new Date()
      expires.setTime(expires.getTime() + (hours * 60 * 60 * 1000))
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`
    },
    
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        // Ensure proper trimming
        const [cookieName, ...cookieParts] = cookie.split('=').map(c => c.trim());
        const cookieValue = cookieParts.join('='); // Handles `=` in values
        if (cookieName === name) {
          try {
            return decodeURIComponent(cookieValue); // Decode URI-encoded values
          } catch (e) {
            console.error('Error decoding cookie value:', e);
            return null;
          }
        }
      }
      return null;
    },
    
    getSubjectFullName(data) {
      this.headerSubjectFullName = data;
      this.$emit("passHeaderSubjectFullName", this.headerSubjectFullName);
    },
  },
  computed: {
    role: function () {
      for (var key in this.loadedUserData) {
        if (key == "roles") {
          return this.loadedUserData[key];
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: center;
  background: white;
  min-width: 75vw;
  width: 7vw;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 10px -4px #000000;
}

.message{
  text-align: center;
  padding: 10px;
}

@media only screen and (min-width: 280px) and (max-width: 540px) {
  .message {
    text-align: center;
    padding: 10px;
  }
}
</style>
