import { createStore } from 'vuex'

// old local storage
// const store = createStore({
//   state: {
//     authString: JSON.parse(localStorage.getItem('authString')) || [] // Initialize with the data from local storage if available
//   },
//   mutations: {
//     addAuthString: function(state, payload) {
//       state.authString.push(payload);
//       localStorage.setItem('authString', JSON.stringify(state.authString)); // Save to local storage after mutation
//     }
//   },
//   getters: {
//     getAuthString: function(state) {
//       return state.authString;
//     },
//     removeAuthString: function(state) {
//       state.authString = [];
//       localStorage.removeItem('authString'); // Clear from local storage after mutation
//     }
//   },
// })

const store = createStore({
  state: {
    authString: JSON.parse(getCookie('authString') || '[]') // Initialize with the data from cookies if available
  },
  mutations: {
    addAuthString: function (state, payload) {
      state.authString.push(payload);
      setCookie('authString', JSON.stringify(state.authString), 7); // Save to cookies with an expiration of 7 days
    }
  },
  getters: {
    getAuthString: function (state) {
      return state.authString;
    },
    removeAuthString: function (state) {
      state.authString = [];
      deleteCookie('authString'); // Clear from cookies
    }
  },
});

// Helper functions for managing cookies
function setCookie(name, value, days) {
  const expires = days
    ? `; expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}`
    : '';
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}

function getCookie(name) {
  const cookieArr = document.cookie.split('; ');
  for (let cookie of cookieArr) {
    // Split only at the first "=" to handle "=" in the cookie value
    const [key, ...valueParts] = cookie.split('=');
    const value = valueParts.join('='); // Rejoin in case the value contains "="
    if (key === name) {
      try {
        return decodeURIComponent(value); // Decode URI-encoded values safely
      } catch (error) {
        console.error(`Error decoding cookie value for ${name}:`, error);
        return null;
      }
    }
  }
  return null; // Return null if the cookie is not found
}


function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

  export default store